.button {
  border: none;
  margin-block-start: 10px;
  padding-inline-start: 40px;
  padding-inline-end: 40px;
  padding-block-start: 18px;
  padding-block-end: 18px;
  border-radius: 10px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: 1s all ease;
}

.button-Nav {
  border: none;
  background-color: var(--AlternativeBlue);
  padding: 9px 22px;
  border-radius: 10px;
  font: var(--headLineMobile2);
  color: var(--white);
  font-weight: 600;
}
.button-outline {
  background-color: var(--Nocolor);
  font: var(--headLineMobile2);
  color: var(--blue);
  border: 2px solid var(--blue);
}
.button-solid {
  background-color: var(--white);
  font: var(--headLineMobile2);
  color: var(--blue);
}
.button::before {
  background: var(--blue);
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: all 0.6s ease;
  color: var(--white);
}

.button::before {
  width: 100%;
  height: 0%;
}
.button:hover::before {
  height: 100%;
}
.button:hover {
  background-color: var(--blue);
  color: var(--white);
}
