.Hero {
  padding-bottom: 80px;
  background: var(--gradient);
}

.Hero-Container {
  min-width: 360px;
  max-width: 1366px;
  margin: 0 auto;
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Hero-Text {
  border: 2px solid transparent;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.Hero-Image {
  margin-inline-start: 10px;
  margin-inline-end: 10px;
  margin-block-start: 66px;
  margin-block-end: 35px;
  width: 333px;
}

.Hero-Image img {
  width: 100%;
  height: 100%;
}

.Hero-Text h1 {
  font: var(--headLineMobile1);
  font-weight: 500;
  font-family: var(--fontBase);
  color: var(--white);
}

.Hero-Text p {
  font: var(--headLineMobile2);
  font-weight: 500;
  font-family: var(--fontBase);
  color: var(--white);
  letter-spacing: 0.02em;
  color: var(--softblue);
}

.Hero-Text button {
  border: none;
  margin-block-start: 10px;
  padding-inline-start: 40px;
  padding-inline-end: 40px;
  padding-block-start: 18px;
  padding-block-end: 18px;
  border-radius: 10px;
  background-color: var(--white);
  font: var(--headLineMobile2);
  color: var(--blue);
  font-weight: 600;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: 1s all ease;
}

.Hero-Text button::before {
  background: var(--blue);
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: all 0.6s ease;
  color: var(--white);
}

.Hero-Text button::before {
  width: 100%;
  height: 0%;
}
.Hero-Text button:hover::before {
  height: 100%;
}
.Hero-Text button:hover {
  background-color: var(--blue);
  color: var(--white);
}

@media screen and (min-width: 768px) {
  .Hero {
    padding-bottom: 0px;
  }
  .Hero-Container {
    flex-direction: row;
    text-align: center;
    padding: 40px;
  }
  .Hero-Image {
    order: 2;
    width: 50%;
    max-width: 588px;
    max-height: 488px;
  }
  .Hero-Text {
    order: 1;
    width: 50%;
    align-items: flex-start;
    text-align: left;
  }
}

@media screen and (min-width: 1024px) {
  .Hero {
    padding: 77px 128px 90px 100px;
  }
  .Hero-Container {
    gap: 100px;
  }
  .Hero-Text h1 {
    font: var(--headLine1);
  }
}
