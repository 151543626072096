.NotFound {
  display: flex;
  align-items: center;
  justify-content: center;
}

.NotFound-Container {
  min-width: 360px;
  max-width: 1366px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px 18px;
}

.NotFound-Content {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NotFound-Content h1,
.NotFound-Content h2,
.NotFound-Content p {
  color: black;
}

.NotFound-Image img {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .NotFound-Content {
    margin: 80px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .NotFound-Image {
    order: 1;
  }
  .Hero-Text p {
    max-width: 500px;
  }
}
