.Services {
  width: 100%;
}
.Services-Container {
  min-width: 360px;
  max-width: 1366px;
  margin: 0 auto;
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Services-container-Text {
  margin-top: 65px;
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.Services-container-Text h2 {
  font: var(--headLine2);
  font-weight: 600;
  letter-spacing: 0.02em;
  color: var(--alternativeBlack);
}

.Services-container-Text p {
  max-width: 500px;
  font: var(--body1);
  color: var(--gray);
  text-align: center;
  font-weight: 300;
  letter-spacing: 0.02em;
  color: var(--alternativeBlack);
}
