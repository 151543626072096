.Service {
  width: 100%;
  margin-block-end: 90px;
}

.Service-Container {
  border: 2px solid transparent;
  min-width: 360px;
  max-width: 1366px;
  margin: 0 auto;
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.Service-Container-Image img {
  width: 100%;
  height: auto;
}

.Service-Container-Text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.Service-Container-Text h3 {
  font: var(--headLineMobile3);
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--alternativeBlack);
}

.Service-Container-Text h3::after {
  border-left: 1.5em solid #0072ff;
  display: block;
  content: "";
  height: 2px;
  margin-top: 0.5em;
}

.Service-Container-Description {
  margin: 0 auto;

  max-width: 600px;
}
.Service-Container-Description p {
  font: var(--body1);
  margin-bottom: 25px;
  text-align: justify;
  text-align: center;
  font-weight: 300;
  letter-spacing: 0.02em;
  color: var(--gray);
}

@media screen and (min-width: 768px) {
  .Service-Container {
    flex-direction: row;
  }

  .Service-Container-Text {
    align-items: flex-start;
  }
  .Service-Container-Text h3 {
    text-align: left;
  }
  .Service-Container-Description p {
    text-align: left;
    max-width: 450px;
    margin-right: 60px;
  }
  .Odd {
    order: 1;
  }
}

@media screen and (min-width: 1024px) {
  .Service-Container-Text {
    border: 2px solid transparent;
    width: 60%;
  }
  .Service-Container-Description {
    margin-left: 0;
    max-width: 500px;
  }
}
