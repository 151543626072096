body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  background-color: var(--bg);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
}

:root {
  /*font size variable*/
  --fontBase: "Nunito Sans", sans-serif;
  --fontAlternative: "Oxygen", sans-serif;

  --headLineMobile1: 35px/42px var(--fontBase);
  --headLineMobile2: 16px/19px var(--fontBase);
  --headLineMobile3: bold 33px/40px var(--fontBase);

  --headLine1: bold 45px/52px var(--fontBase);
  --headLine2: 28px/34px var(--fontBase);
  --headLine3: bold 48px/77px var(--fontBase);

  --body1: 16px/19px var(--fontBase);

  --headLine4: bold 34px/55px var(--fontBase);
  --headLine5: bold 24px/38px var(--fontBase);
  /*bg color variable*/
  --bg: #f8f9fb;
  --gradient: linear-gradient(90deg, #0072ff 3.26%, #00c6ff 79.34%);
  backdrop-filter: blur(39.7657px);
  /*color variable*/
  --white: white;
  --softblue: #a7ffff;
  --blue: #356fcd;
  --AlternativeBlue: #0271fe;
  --alternativeBlack: #373739;
  --black2: #6a6a6a;
  --gray: #7b839e;
  --gray2: #999fb3;
  --hardBlue: #2c2f3b;
  --Nocolor: transparent;
}
